import { AfterViewInit, Component, Input } from '@angular/core';
import * as QRCode from 'qrcode';

@Component({
    selector: 'app-qr-code',
    standalone: true,
    imports: [],
    templateUrl: './qr-code.component.html',
    styleUrl: './qr-code.component.scss',
})
export class QrCodeComponent implements AfterViewInit {
    @Input() value: string = '';
    @Input() size: number = 256;

    ngAfterViewInit() {
        const canvas = document.querySelector('canvas');
        if (canvas && this.value) {
            QRCode.toCanvas(canvas, this.value, {
                width: this.size,
            });
        }
    }
}
