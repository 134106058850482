<div class="flex flex-col items-center justify-center min-h-screen bg-primary-500 p-6 min-w-screen">
    <!-- Logo -->
    <div class="mb-8">
        <img src="images/logo/visi_pass_logo.png" alt="Logo" class="w-24 h-24 object-contain" />
    </div>

    <!-- QR Code -->
    <div id="qr-container" class="flex flex-col items-center bg-white shadow-lg p-6 rounded-lg">
        <div class="mb-4 text-center">
            <h1 class="text-lg font-bold text-gray-800">Scannez le QR Code</h1>
            <p class="text-gray-600 text-sm">
                Vérifiez les informations rapidement et en toute sécurité.
            </p>
        </div>
        <app-qr-code [value]="qrData" [size]="200"></app-qr-code>
    </div>
    <!-- Bouton de téléchargement -->
    <button (click)="downloadQrCode()"
        class="mt-4 px-6 py-2 bg-primary-700 text-white rounded-lg shadow hover:bg-primary-600 transition">
        Télécharger le QR Code
    </button>
</div>