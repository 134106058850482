import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QrCodeComponent } from 'app/layout/common/qr-code/qr-code.component';
import * as htmlToImage from 'html-to-image';

@Component({
    selector: 'app-page-qr-code-validation',
    templateUrl: './page-qr-code-validation.component.html',
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [QrCodeComponent],
})
export class PageQrCodeValidationComponent implements OnInit {
    qrData: string = '';
    type: string = '';
    id: string = '';

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.type = params['type'];
            this.id = params['id'];

            if (this.type && this.id) {
                this.qrData = JSON.stringify({ type: this.type, id: this.id });
            }
        });
    }

    // Méthode pour télécharger le QR Code
    // downloadQrCode(): void {
    //     const qrContainer = document.getElementById('qr-container');

    //     if (qrContainer) {
    //         htmlToImage
    //             .toPng(qrContainer)
    //             .then((dataUrl) => {
    //                 const link = document.createElement('a');
    //                 link.href = dataUrl;
    //                 link.download = 'qr-code.png';
    //                 link.click();
    //             })
    //             .catch((error) => {
    //                 console.error(
    //                     "Erreur lors du téléchargement de l'image :",
    //                     error
    //                 );
    //             });
    //     }
    // }

    downloadQrCode(): void {
        const qrContainer = document.getElementById('qr-container');

        if (qrContainer) {
            htmlToImage
                .toPng(qrContainer, {
                    filter: (node) => {
                        // Filtrer les nœuds que vous ne voulez pas inclure (facultatif)
                        return true;
                    },
                    skipFonts: true, // Ignore les polices externes
                })
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = 'qr-code.png';
                    link.click();
                })
                .catch((error) => {
                    console.error(
                        "Erreur lors du téléchargement de l'image :",
                        error
                    );
                });
        }
    }
}
